.menu-item {
    display: flex;
    white-space: nowrap;

    a {
        text-decoration: none;
        color: black;
        opacity: 0.5;
        margin-left: 30px;
        transition: all 300 ease;
        transition-duration: 0.5s;

        &:hover {
            opacity: 1;
        }
    }
}
