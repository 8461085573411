.gallery-link {
    margin-bottom: 0;

    a {
        color: black;
        opacity: 0.5;
        text-decoration: none;
        font-size: 120%;
        line-height: 60%;
        margin-bottom: 20px;
        transition: all 300 ease;
        transition-duration: 0.5s;

        &:hover {
            opacity: 1;
        }
    }

    &__description {
        font-size: 70%;
    }
}