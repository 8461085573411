body {
    margin: 0px;
    font-family: "Gentium Basic", serif;
    font-size: 30px;
    cursor: default;

    ::selection {
        color: white;
        background-color: black;
    }
}

#root {
    display: flex;
    flex: 1;
}

h1 {
    margin: 0px;
    font-weight: 700;
}

b {
    font-weight: 700;
}