.menu {
    display: flex;
    font-size: 40px;
    flex-direction: row;
    justify-content: flex-end;
    margin: 20px;
    align-items: center;
}

.instagram_logo {
    height: 35px;
    margin:0 10px 0 30px;
    opacity: 0.5;
    cursor: pointer;
    transition: all 300 ease;
    transition-duration: 0.5s;

    &:hover {
        opacity: 1;
    }
}
