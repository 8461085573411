.home-photo {
    background-image: url("assets/home_photo.jpg");
    position: relative;
    align-self: flex-end;
    width: 546px;
    height: 262px;
    margin-top: 25px;
    border-radius: 2px;
}

.contact-name {
    font-size: 120%;
    font-weight: 700;
}