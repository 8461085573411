.header {
    background-image: url("assets/background.jpg");
    height: 200px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;

    &__ribbon {
        background-image: url("assets/ribbon.png");
        width: 800px;
        height: 200px;
        color: white;
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        background-position: center;
        flex-direction: column;
        filter: drop-shadow(5px 5px 3px rgba(0, 0, 0, 0.4));

        &__title {
            font-family: "Merriweather", serif;
            transform: rotate(-10deg);
            top: -12px;
            position: relative;
            line-height: 31px;
            filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.4));

            &__subtitle {
                font-size: 13px;
                font-weight: 300;
            }

            &__title {
                font-size: 52px;
                font-weight: 400;
            }
        }
    }
}